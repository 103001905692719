import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormBuilder} from '@angular/forms';
import {AuthService} from "src/app/core/services/auth.service";
import {Router} from '@angular/router';
import {SnackBarService} from '../../../services/SnackBar/snack-bar.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  // @ts-ignore
  loginForm: FormGroup;
  username = new FormControl('');
  password = new FormControl('');
  validating = false;

  constructor(private formBuilder: FormBuilder,
              private auth: AuthService,
              private router: Router,
              private snackBar: SnackBarService
  ) {
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: this.username,
      password: this.password
    });
  }

  validate(): void {
    this.auth.login(this.username.value, this.password.value).subscribe({
      next: (response) => {
        const {name, last_name} = response
        this.snackBar.openSnackBar(`Welcome ${name} ${last_name}`, 'Close');
      },
      error: (error) => {
        console.log(error);
        this.snackBar.openSnackBar(error.error?.message, 'Close');
        this.validating = false;
      }
    })
  }

}
