import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthLayoutComponent} from "./layouts/auth-layout/auth-layout.component";
import {MainLayoutComponent} from "./layouts/main-layout/main-layout.component";
import {AuthenticationGuard} from './core/guards/authentication.guard';
import {RedirectIfAuthenticatedGuard} from "./core/guards/redirect-if-authenticated.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./modules/dashboard/dashboard.module')
          .then((m) => m.DashboardModule)
      },
      {
        path: 'users',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./modules/user/user.module')
          .then((m) => m.UserModule)
      },
      {
        path: 'rooms',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./modules/room/room.module')
          .then((m) => m.RoomModule)
      },
      {
        path: 'rates',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./modules/rate/rate.module')
          .then((m) => m.RateModule)
      },
      {
        path: 'providers',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./modules/provider/provider.module')
          .then((m) => m.ProviderModule)
      },
      {
        path: 'promotions',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./modules/promotion/promotion.module')
          .then((m) => m.PromotionModule)
      },
      {
        path: 'reservations',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./modules/reservation/reservation.module')
          .then((m) => m.ReservationModule)
      },
      {
        path: 'clients',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./modules/client/client.module')
          .then((m) => m.ClientModule)
      },
      {
        path: 'reports',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./modules/report/report.module')
          .then((m) => m.ReportModule)
      },
      {
        path: 'agents',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./modules/agent/agent.module')
          .then((m) => m.AgentModule)
      },
      {
        path: 'currencies',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./modules/currency/currency.module')
          .then((m) => m.CurrencyModule)
      },
      {
        path: 'availabilities',
        canActivate: [AuthenticationGuard],
        loadChildren: () => import('./modules/availability/availability.module')
          .then((m) => m.AvailabilityModule)
      },
    ]
  },
  {
    path: 'auth',
    canActivate: [RedirectIfAuthenticatedGuard],
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
