import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subject, take} from "rxjs";
import {tap, map} from 'rxjs/operators';
import {ApiService} from 'src/app/core/services/api.service';
import {IAuthUser, IUser} from 'src/app/models'
import {Router, UrlTree} from "@angular/router";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user = new ReplaySubject<IUser | null>(1);
  $user = this.user.asObservable();


  constructor(
    private apiService: ApiService,
    private router: Router
  ) {
  }


  login(email: string, password: string): Observable<IUser> {
    return this.apiService.post<IAuthUser>('/auth/login', {email, password})
      .pipe(
        tap((response) => {
          const {access_token, user} = response;
          localStorage.setItem("token", access_token);
          this.user.next(user);
          this.router.navigate(['/dashboard'], {replaceUrl: true})
        }),
        map((res) => res?.user)
      )
  }

  logout(): void {
    localStorage.removeItem('token');
    this.user.next(null);
    this.router.navigate(['/auth/login'], {replaceUrl: true});
  }

  me(): void {
    this.apiService.get<IUser>('/auth/me').subscribe({
      next: (res) => {
        this.user.next(res);
      },
      error: () => {
        this.user.next(null);
      }
    })
  }

  getToken() {
    return localStorage.getItem("token");
  }

  isUserAuthenticated(): boolean {
    return !!this.getToken();
  }
}
