import {Component, OnInit} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import {Router} from '@angular/router';
import {AuthService} from "@core/services/auth.service";

interface Node {
  route: string,
  name: string;
  children?: Array<Node>;
}

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  route: string;
}

const MENU_ITEMS: Array<Node> = [
  {
    name: 'Dashboard',
    route: 'dashboard'
  },
  {
    name: 'Usuarios',
    route: 'users'
  },
  {
    name: 'Habitaciones',
    route: 'rooms'
  },
  {
    name: 'Agentes',
    route: 'agents'
  },
  {
    name: 'Proveedores',
    route: 'providers'
  },
  {
    name: 'Disponibilidades',
    route: 'availabilities'
  },
  {
    name: 'Reservaciones',
    route: 'reservations'
  },
  {
    name: 'Tarifas',
    route: 'rates'
  },
  {
    name: 'Monedas',
    route: 'currencies'
  },
  {
    name: 'Reportes',
    route: 'reports'
  }
]

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  private _transform = (node: Node, lvl: number): FlatNode => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: lvl,
      route: node.route
    }
  }

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable
  )

  treeFlat = new MatTreeFlattener(
    this._transform,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlat);

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.dataSource.data = MENU_ITEMS;
  }

  hasChildren(el: number, node: FlatNode): boolean {
    return node.expandable
  }

  ngOnInit(): void {
  }

  logOut = () => {
    this.authService.logout();
  }

}
