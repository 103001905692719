<mat-toolbar color="primary" class="mat-elevation-z10">
  <button mat-icon-button>
    <mat-icon>menu</mat-icon>
  </button>
  <span>Admin</span>
  <span class="example-spacer"></span>
  <button [matMenuTriggerFor]="menu" mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="logOut()" routerLink="/auth/login">
      <mat-icon>exit_to_app</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
</mat-toolbar>
<div class="main-container">
  <aside class="aside-menu">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="menu-list">
      <mat-tree-node *matTreeNodeDef="let node" class="menu-node">
        <button [routerLink]="node.route" routerLinkActive="menu-active" class="menu-button" mat-button>
          {{node.name}}
        </button>
      </mat-tree-node>
    </mat-tree>
  </aside>
  <main class="modules-container">
    <router-outlet></router-outlet>
  </main>
</div>
