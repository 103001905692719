<div class="main">
  <mat-card class="container">
    <div class="title">
      <H1>Login</H1>
      <h3>¡Hello! Log in with your credentials.</h3>
    </div>
    <form class="form" [formGroup]="loginForm">
      <div class="form-field">
        <span>Email Address</span>
        <mat-form-field appearance="outline">
          <input  [formControl]="username" matInput type="text" placeholder="example@mail.com">
        </mat-form-field>
      </div>
      <div class="form-field">
        <span class="label">Password</span>
<!--        <mat-form-field appearance="outline">-->
<!--          <input matInput type="outline">-->
<!--        </mat-form-field>-->
        <mat-form-field appearance="outline">
          <input  [formControl]="password" matInput [type]="hide ? 'password' : 'text'" >
          <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <button mat-raised-button color="primary" type="button" (click)="validate()">Log in</button>
    </form>
  </mat-card>

</div>
