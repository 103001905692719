import {Injectable} from '@angular/core';
import {HttpParams, HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private httpClient: HttpClient) {
  }

  get<T>(url: string, params?: HttpParams) {
    return this.httpClient.get<T>(url, {params})
  }

  post<T>(url: string, data: any, options?: { [index: string]: any }) {
    return this.httpClient.post<T>(url, data, options);
  }

  put<T>(url: string, data: any, options?: { [index: string]: any }) {
    return this.httpClient.put<T>(url, data, options);
  }

  patch<t>(url: string, data: any, options?: { [index: string]: any }) {
    return this.httpClient.patch(url, data, options);
  }

  delete<T>(url: string) {
    return this.httpClient.delete<T>(url);
  }
}
